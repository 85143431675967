import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";

import { api } from "./services/api.js";
import merchantSlice from "./slices/merchantSlice";
import menuSlice from "./slices/menuSlice";

const { configureStore } = require("@reduxjs/toolkit");

export const store = configureStore({
  reducer: {
    merchant: merchantSlice,
    menu: menuSlice,
    [api.reducerPath]: api.reducer
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat([api.middleware])
  ]
});
