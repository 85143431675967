import {fetchBaseQuery, createApi} from "@reduxjs/toolkit/query/react";
import {API} from "../../utils/constants";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL || (process.env.REACT_APP_ENVIRONMENT === 'localhost' ? 'http://'+window.location.host.split(':')[0]+':4001' : "/"),
  headers: {
    "content-type": "application/json"
  }
});

export const api = createApi({
  reducerPath: "api",
  baseQuery,
  endpoints: (builder) => ({
    fetchMerchantInfo: builder.mutation({
      query: () => ({
        url: API.INFO,
        method: "GET"
      })
    }),
    fetchMenuList: builder.mutation({
      query: () => ({
        url: API.MENU_LIST,
        method: "GET"
      })
    }),
    fetchCategoryList: builder.mutation({
      query: ({id}) => ({
        url: `${API.CATEGORY_LIST}${id}/`,
        method: "GET"
      })
    }),
    fetchProductDetail: builder.mutation({
      query: ({id}) => ({
        url: `${API.PRODUCTS}${id}/`,
        method: "GET"
      })
    }),
    fetchAllProducts: builder.mutation({
      query: () => ({
        url: `${API.ALL_PRODUCTS}}`,
        method: "GET"
      })
    }),
    fetchReservationProducts: builder.mutation({
      query: () => ({
        url: `${API.RESERVATION_PRODUCTS}`,
        method: "GET"
      })
    }),
    placeReservation: builder.mutation({
      query: ({body}) => ({
        url: `${API.PLACE_RESERVATION}`,
        method: "POST",
        body
      })
    })
  })
});

export const {
  useFetchMerchantInfoMutation,
  useFetchMenuListMutation,
  useFetchCategoryListMutation,
  useFetchProductDetailMutation,
  useFetchReservationProductsMutation,
  usePlaceReservationMutation
} = api;
