import {CATEGORIES_DUMMY} from "../dummy_categories";
import {useState} from "react";
import {Button, Card, Container, InputGroup, Row, Form} from "react-bootstrap";

function Feedback() {
    const [categories, setCategories] = useState(CATEGORIES_DUMMY);
    return (<>

        <Container fluid style={{width: "390px", background:"#EDEDED"}}>
            <Row className="justify-content-md-center">

                <Card style={{borderRadius: "10px"}}>
                    <Card.Body>

                        <Card.Title> Нам дуже важливо дізнатися про твоє враження, тому ми чекаємо на твій
                            відгук.</Card.Title>
                        <Card.Text>

                            <InputGroup className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Яке твоє враження...."
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    style={{
                                        marginTop:"25px",
                                        border: "none",
                                        background: "#EDEDED",
                                        width: "302px",
                                        height: "201px"
                                    }}
                                />

                            </InputGroup>
                        </Card.Text>
                        <Button variant="dark" style={{
                            width: "332px",
                            height: " 56px"
                        }}>Надіслати відгук</Button>
                    </Card.Body>
                </Card>

            </Row>
        </Container>


    </>);

}

export default Feedback;