import {Row} from "react-bootstrap";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {ICONS} from "../utils/constants";
import {Link} from "react-router-dom";

function SecondaryIcon(props) {
    const {icon} = props;
    return <svg className="icon icon-small icon-Frame-4">
        <use xlinkHref={`sprite.svg#${icon}`}></use>
    </svg>;
}

const Wrapper = ({text, link, children}) => (link ?
        <a href={link} className={'p-0 m-0'} target={'_blank'} rel="noreferrer">{children}</a>
        : <CopyToClipboard text={text}>{children}</CopyToClipboard>
);

function OffCanvasInfoRow(props) {
    const {primaryText, secondaryText, icon, link} = props;
    return (<div className={'offcanvas-row'}>
        <Wrapper text={secondaryText} link={link}><>
            <div className="row align-items-center">
                <div className="col-auto flex-grow-0 flex-shrink-0" style={{width: '52px'}}>
                    <svg className="icon icon-Frame-4">
                        <use xlinkHref={`sprite.svg#${icon}`}></use>
                    </svg>
                </div>
                <div className="col text-white">
                     <div className={'primary-text'}>
                        {primaryText}:
                    </div>
                    <div className={'secondary-text'}>
                        {secondaryText}
                    </div>
                </div>
                <div className="col-auto flex-grow-0 flex-shrink-0" style={{width: '52px'}}>  {!link && <span><SecondaryIcon icon={ICONS.COPY}/></span>}
                    {link && <span>  <SecondaryIcon icon={ICONS.LINK}/></span>}</div>
            </div>
            {/*<div className={'row-content d-flex align-items-center justify-content-start'}>*/}
            {/*    <div className={'col'}>*/}
            {/*        <svg className="icon icon-Frame-4">*/}
            {/*            <use xlinkHref={`sprite.svg#${icon}`}></use>*/}
            {/*        </svg>*/}
            {/*    </div>*/}
            {/*    <div className={'col-auto'}>*/}
            {/*        <div className={'primary-text'}>*/}
            {/*            {primaryText}:*/}
            {/*        </div>*/}
            {/*        <div className={'secondary-text'}>*/}
            {/*            {secondaryText}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className={'col text-end'}>*/}
            {/*        {!link && <span><SecondaryIcon icon={ICONS.COPY}/></span>}*/}
            {/*        {link && <span>  <SecondaryIcon icon={ICONS.LINK}/></span>}*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
        </Wrapper>
    </div>);
}

export default OffCanvasInfoRow;