import {Card, Container, Figure, Image, Row, Form} from "react-bootstrap";
import CustomNavbar from "../components/CustomNavbar";
import PoweredBy from "../components/PoweredBy";
import {ICONS} from "../utils/constants";
import {useFetchProductDetailMutation} from "../store/services/api";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import CustomSpinner from "../components/CustomSpinner";


function ProductDetails() {
    const [product, setProduct] = useState(null);
    const [fetchProduct, {isLoading: isLoadingFetchProduct}] = useFetchProductDetailMutation();
    let {id} = useParams();

    useEffect(() => {
        const fetchData = async () => {
            const product = await fetchProduct({id});
            setProduct(product.data.data);
        };
        fetchData()
            .catch(console.error);

    }, []);
    return (<>
        <CustomNavbar/>
        {isLoadingFetchProduct && <CustomSpinner/>}

        {product && (<Container className={'product-details'}>
            <Row className="justify-content-md-center">
                <Card className={'text-center'}>
                    <Card.Body>
                        <Card.Title>{product.name}</Card.Title>
                        {product.description && <Card.Text>
                            <p className="second-name">{product.description}</p>
                        </Card.Text>}
                        <div className={'details-block d-flex justify-content-center'}>
                            {product.calories && <div className="details-info-block-wrap">
                                <div className={'details-info-block'}>
                                    <svg className="icon icon-Frame-4">
                                        <use xlinkHref={`/sprite.svg#icon-calories`}></use>
                                    </svg>
                                    <span>product.calories калорій</span>
                                </div>
                            </div>}
                            {product.weight && <div className="details-info-block-wrap">
                                <div className={'details-info-block '}>
                                    <svg className="icon icon-Frame-4">
                                        <use xlinkHref={`/sprite.svg#icon-weight`}></use>
                                    </svg>
                                    <span>{product.weight} грам</span>
                                </div>
                            </div>}
                             {product.time_to_prepare && <div className="details-info-block-wrap">
                                <div className={'details-info-block '}>
                                    <svg className="icon icon-Frame-4">
                                        <use xlinkHref={`/sprite.svg#icon-time`}></use>
                                    </svg>
                                    <span>{product.time_to_prepare} хвилин</span>
                                </div>
                            </div>}

                        </div>
                                                <div className={'details-block'}>
                            <span className={'price rounded'}>{product.price} uah</span>
                        </div>
                    </Card.Body>
                </Card>


                <div className={'footer text-center'}>
                    <PoweredBy/>
                </div>
            </Row>
        </Container>)}

    </>);
}

export default ProductDetails;