import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

function CustomPrimaryButton(props) {
    const {name, link} = props;
    const navigate = useNavigate();
    return <Button onClick={() => link?navigate(link):null} variant={"dark"}>{name}</Button>;
}

export default CustomPrimaryButton;
