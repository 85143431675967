import {useSelector} from "react-redux";
import {Container, Image, Row} from "react-bootstrap";

function RestaurantInfoBlock(){
    const {merchantInfo} = useSelector((state) => state.merchant);
    return <div className={'restaurant-info-block'}>
        <Container className={'d-flex align-items-center'}>
            <div className={'logo-block'}><Image className={'logo-image'} src={merchantInfo.logo}/></div>
            <div className={'merchant-name'}>{merchantInfo.name}</div>
        </Container>

    </div>
}
export default RestaurantInfoBlock
