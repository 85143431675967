import {useEffect, useState} from "react";

function CategoriesMenu(props) {
  const {categories, categoryChanged} = props;
  const [activeCategory, setActiveCategory] = useState(null);


  function selectCategory(id) {
    setActiveCategory(id);
    categoryChanged(id);
  }

  useEffect(() => {
    setActiveCategory(categories[0].id);
    categoryChanged(categories[0].id);
  }, []);

  return (
    <>
      <div className={'categories sticky-top'}>
        <div className={'container'}>
          {categories && categories.length > 0 && categories.map(category => {
            return (
              <span key={category.id}
                    className={`category noselect mt-2 mb-2 ${activeCategory === category.id ? 'active' : ''}`}
                    title={category.name}
                    onClick={() => selectCategory(category.id)}>
                            {category.name}
                        </span>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default CategoriesMenu;