import {useEffect, useState} from "react";
import CategoriesMenu from "./CategoriesMenu";
import Products from "./Products";
import {useSelector} from "react-redux";

function Categories(props) {
    const {menuId} = props
    const {menus} = useSelector((state) => state.menu);
    const [categories, setCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState(null);
    function categoryChanged(id){
        const catObj = menus.find(m => m.id === menuId).categories.find(c => c.id.toString()===id.toString())
        setActiveCategory(catObj)
    }

    useEffect(() => {
        setCategories(menus.find(m => m.id === menuId).categories)
    }, []);

    return (
        <>
            { categories && categories.length>0 && <CategoriesMenu categories={categories} categoryChanged={categoryChanged}/>}
            { activeCategory && <Products products={activeCategory.products}/>}
        </>
    );
}

export default Categories;