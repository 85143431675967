import {Card, Container, Row} from "react-bootstrap";
import CustomNavbar from "../components/CustomNavbar";
import PoweredBy from "../components/PoweredBy";
import {useEffect} from "react";


function ReservationSuccess() {



  useEffect(() => {
    const fetchData = async () => {

    };
    fetchData()
      .catch(console.error);

  }, []);
  return (<>
    <CustomNavbar/>

    {(<Container className={'product-details'}>
      <Row className="justify-content-md-center">

        <Card className={'text-center mb-3'}>
          <Card.Body>
            <Card.Title>{'🎉 Congratulations, your reservation is placed on approval'}</Card.Title>
            <Card.Body>{'You will receive an sms confirmation once we approve'}</Card.Body>
          </Card.Body>
        </Card>
        <Container>

        </Container>
        <div className={'footer text-center'}>
          <PoweredBy/>
        </div>

      </Row>
    </Container>)}

  </>);
}

export default ReservationSuccess;