import { createSlice } from "@reduxjs/toolkit";
import { api } from "../services/api";

const initialState = {
  merchantInfo: []
};

const merchantSlice = createSlice({
  name: "merchant",
  initialState,
  reducers: {
    updateItems: (state, action) => {
      state.items = state.items.filter(
        (item) => item.id !== action.payload.data
      );
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        api.endpoints.fetchMerchantInfo.matchFulfilled,
        (state, action) => {
          state.merchantInfo = action.payload.data;
        }
      )
  }
});

const { reducer } = merchantSlice;

export default reducer;
