import {useEffect, useState} from "react";
import {useFetchCategoryListMutation} from "../store/services/api";
import CategoriesMenu from "./CategoriesMenu";
import {Card, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {ICONS} from "../utils/constants";

function ProductListItem(props) {
    const {product} = props;
    const navigate = useNavigate();
    return (<Row className="justify-content-md-center p-3 pb-0 product-row" key={product.id}>
        <Card className={'product-card w-100'} onClick={() => navigate(`products/${product.id}`)}>
            {/*<Card.Img variant="top"*/}
            {/*          style={{borderTopRightRadius: "20px", borderTopLeftRadius: "20px"}}*/}
            {/*          src={picMenu1}/>*/}
            <Card.Body className={''}>
                <Card.Title className={''}>{product.name}</Card.Title>
                {/*{product.description && (*/}
                {/*    <Card.Text>*/}
                {/*        <p className="second-name">{product.description}</p>*/}
                {/*    </Card.Text>*/}
                {/*)}*/}
                <div className={'product-list-details'}>
                    <div className={'price-col5'}>
                        <span className={'price rounded'}>{product.price} uah</span>
                    </div>
                    <div className={'arrow-details-link'}>
                        <svg className="icon icon-Frame-4">
                            <use xlinkHref={`/sprite.svg#${ICONS.RIGHT}`}></use>
                        </svg>
                    </div>
                </div>

            </Card.Body>

        </Card>
    </Row>);

}

export default ProductListItem;