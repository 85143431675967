import { createSlice } from "@reduxjs/toolkit";
import { api } from "../services/api";

const initialState = {
  menus: null
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    updateItems: (state, action) => {
      state.items = state.items.filter(
        (item) => item.id !== action.payload.data.results
      );
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        api.endpoints.fetchMenuList.matchFulfilled,
        (state, action) => {
          state.menus = action.payload.data.results;
        }
      )
  }
});

const { reducer } = menuSlice;

export default reducer;
