import {Outlet} from "react-router-dom";
import {
  useFetchMenuListMutation,
  useFetchMerchantInfoMutation
} from "../store/services/api";
import {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import {useSelector} from "react-redux";
import CustomSpinner from "./CustomSpinner";

function Layout() {

  const [fetchMerchantInfo, {isLoading}] = useFetchMerchantInfoMutation();
  const [fetchMenuList, {isLoadingMenuList}] = useFetchMenuListMutation();
  const {merchantInfo} = useSelector((state) => state.merchant);

  useEffect(() => {
    fetchMerchantInfo();
    fetchMenuList();
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${merchantInfo?.name || (!isLoading && 'No found')} - JustQR`}</title>
        {/*<meta name="description" content="From Helmet" />*/}
        {merchantInfo && <meta
          name="description"
          content={`${merchantInfo?.description || merchantInfo?.name} - JustQR`}
        />}
        {!merchantInfo && !isLoading && <meta
          name="description"
          content={`No found - JustQR`}
        />}

        {/*<!-- Google / Search Engine Tags -->*/}
        <meta itemProp="name" content="JustQR"/>
        <meta itemProp="description" content={`${merchantInfo?.description || merchantInfo?.name || (!isLoading && 'No found')} - JustQR`}/>
        <meta itemProp="image" content=""/>

        {merchantInfo.active_theme && <>
          <style>{`body { background-color: ${merchantInfo.active_theme.background_color}; }`}</style>
          <style>{`.categories .category.active { color: ${merchantInfo.active_theme.active_link_color}; }`}</style>
          <style>{`.product-list .card .arrow-details-link  { background: ${merchantInfo.active_theme.active_link_color}; }`}</style>
        </>}
      </Helmet>
      {(isLoading || isLoadingMenuList) && <CustomSpinner/>}
      {(!isLoading || !isLoadingMenuList) && !merchantInfo &&
        <span>Ooops, restaurant you are looking for does not exist...</span>}
      {(!isLoading || !isLoadingMenuList) && <>
        <div className={''}>
          <Outlet/>
        </div>
      </>}
    </>
  );
}

export default Layout;