import {useEffect, useState} from "react";
import {useFetchCategoryListMutation} from "../store/services/api";
import CategoriesMenu from "./CategoriesMenu";
import ProductListItem from "./Product";
import {Container} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

function Products(props) {
  const {products} = props;
  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {

    };
    fetchData()
      .catch(console.error);
  }, []);

  return (
    <><Container className={"product-list "}>
      {products && products.length > 0 && products.map(product => (
        <ProductListItem key={product.id} className={"ff"} product={product}
                         onClick={() => navigate(`/products/${product.id}`)}/>
      ))}
    </Container>
    </>
  );
}

export default Products;