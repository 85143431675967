import "./home.css";
import {useSelector} from "react-redux";
import Categories from "../components/Categories";
import CustomNavbar from "../components/CustomNavbar";
import PoweredBy from "../components/PoweredBy";
import RestaurantInfoBlock from "../components/RestaurantInfoBlock";
import CustomPrimaryButton from "../components/CustomPrimaryButton";


function Home() {
    const {merchantInfo} = useSelector((state) => state.merchant);
    const {menus} = useSelector((state) => state.menu);

    if (menus && menus.length === 0 && merchantInfo.type_of_business !== 'car_wash') {
        return (<div className="d-flex align-items-center justify-content-center p-5" style={{height: 100 + 'vh'}}>
            Ooops, looks like menu is not filled yet.<br/> Try again later...</div>);
    }
    //

    if (menus && menus.length > 0 || merchantInfo.type_of_business === 'car_wash') {
        return (<>
                <div className={''}>
                    <CustomNavbar isOffCanvas={true}/>
                    { merchantInfo.logo && <RestaurantInfoBlock/>}
                    {/*<div className={'place-details'}>*/}
                    {/*    <Container>*/}
                    {/*        <div className={'d-flex align-items-center mt-4 mb-4'}>*/}
                    {/*            /!*<div>*!/*/}
                    {/*            /!*    <Figure.Image*!/*/}
                    {/*            /!*        className="logo me-4 "*!/*/}
                    {/*            /!*        alt="logo"*!/*/}
                    {/*            /!*        src={secondLogo}*!/*/}
                    {/*            /!*    />*!/*/}
                    {/*            /!*</div>*!/*/}

                    {/*            /!*<Card.Title>{merchantInfo?.name}</Card.Title>*!/*/}
                    {/*            /!*<Card.Text>*!/*/}
                    {/*            /!*    <span className="second-name">{merchantInfo?.description}</span>*!/*/}
                    {/*            /!*</Card.Text>*!/*/}
                    {/*        </div>*/}
                    {/*    </Container>*/}
                    {/*</div>*/}
                    <div>
                        {menus && menus[0] && <Categories menuId={menus[0].id}/>}
                    </div>
                    <div className={'footer text-center'}>
                       <PoweredBy/>
                    </div>
                    {  merchantInfo.type_of_business === 'car_wash' && (
                      <div className={'fixed-bottom text-center mb-3'}>
                          <CustomPrimaryButton name={'Make reservation'} link={'/reservation'}/>
                      </div>
                    )}
                </div>
            </>
        );
    }
    return <>Loading...</>;

}

export default Home;