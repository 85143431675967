import {Container, Navbar, Offcanvas} from "react-bootstrap";
import OffCanvasInfoRow from "./OffCanvasInfoRow";
import {ICONS} from "../utils/constants";
import {useSelector} from "react-redux";
import {useState} from "react";
import BackBtn from "./BackBtn";

function CustomNavbar(props) {
    const {isOffCanvas} = props;
    const {merchantInfo} = useSelector((state) => state.merchant);
    const [show, setShow] = useState(false);

    return <Navbar collapseOnSelect expand={false} bg="light" variant="light">
        <Container>
            <div className={'col-2'}>
                 {isOffCanvas && <Navbar.Toggle className={'burger-button'}
                               aria-controls="responsive-navbar-nav"
                               onClick={() => setShow(true)}/> }
                {!isOffCanvas && <BackBtn/>}
            </div>
            <div className={'col-8 merchant-name text-center'}>{!merchantInfo.logo && merchantInfo.name}</div>
            <div className={'col-2'}></div>
            {isOffCanvas && <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-sm`}
                placement="start"
                show={show}

            >
                <Offcanvas.Header className={''}>
                    <Container className={'d-flex align-items-center justify-content-start'}>
                        <button
                            className={'btn-close btn-close position-absolute start-100 ms-4 bg-white opacity-100'}
                            onClick={() => setShow(!show)}></button>
                        {/*<div className={'w-20'}><Image*/}
                        {/*    className="logo" c*/}
                        {/*    alt="logo"*/}
                        {/*    src={secondLogo}*/}
                        {/*/></div>*/}

                        <div className={'w-80 ms-3'}>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                                { merchantInfo.name}
                            </Offcanvas.Title>
                        </div>
                    </Container>
                </Offcanvas.Header>
                {merchantInfo && merchantInfo.merchant_contacts && <Offcanvas.Body>
                    <Container>
                        <div className={'offcanvas-card'}>
                            {(merchantInfo.merchant_contacts.wifi_name || merchantInfo.merchant_contacts.wifi_pass) &&
                                <OffCanvasInfoRow primaryText={`WiFi ${merchantInfo.merchant_contacts.wifi_name?'('+merchantInfo.merchant_contacts.wifi_name+')':''}`}
                                                  secondaryText={merchantInfo.merchant_contacts.wifi_pass}
                                                  icon={ICONS.WIFI}/>}
                            {merchantInfo.merchant_contacts.address &&
                                <OffCanvasInfoRow primaryText={'Адреса'}
                                                  secondaryText={merchantInfo.merchant_contacts.address}
                                                  icon={ICONS.MAP}/>}
                            {merchantInfo.merchant_contacts.phone &&
                                <OffCanvasInfoRow primaryText={'Телефон'}
                                                  secondaryText={merchantInfo.merchant_contacts.phone}
                                                  icon={ICONS.PHONE}
                                                  link={`tel:${merchantInfo.merchant_contacts.phone}`}
                                />}
                            {merchantInfo.merchant_contacts.instagram &&
                                <OffCanvasInfoRow primaryText={'Instagram'}
                                                  secondaryText={merchantInfo.merchant_contacts.instagram}
                                                  icon={ICONS.INSTAGRAM}
                                                  link={`https://instagram.com/${merchantInfo.merchant_contacts.instagram}`}/>}
                        </div>
                    </Container>
                </Offcanvas.Body>}
            </Navbar.Offcanvas>}
        </Container>
    </Navbar>;

}

export default CustomNavbar;