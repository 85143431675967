export const API = {
    INFO: 'api/merchant/',
    MENU_LIST: 'api/menu/list/',
    CATEGORY_LIST: 'api/menu/list/',
    PRODUCTS: 'api/menu/products/',
    ALL_PRODUCTS: 'api/menu/all-products/',
    RESERVATION_PRODUCTS: 'api/menu/reservation-products/',
    PLACE_RESERVATION: 'api/orders/reservation/'
}

export const ICONS = {
    WIFI: 'icon-wifi-logo',
    COPY: 'icon-copy',
    LINK: 'icon-link',
    INSTAGRAM: 'icon-instagram',
    PHONE: 'icon-phone',
    MAP: 'icon-map',
    LEFT: 'icon-arrow-left',
    RIGHT: 'icon-arrow-right'
}