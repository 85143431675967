import {Card, Container, Row, Button, Form as BootstrapForm} from "react-bootstrap";
import CustomNavbar from "../components/CustomNavbar";
import PoweredBy from "../components/PoweredBy";
import {useEffect, useState} from "react";
import CustomSpinner from "../components/CustomSpinner";
import {useSelector} from "react-redux";
import {useFetchReservationProductsMutation, usePlaceReservationMutation} from "../store/services/api";
import {Formik, Form} from "formik";
import {useNavigate} from "react-router-dom";
import {PlaceReservationSchema} from "../utils/validationSchemas";


function Reservation() {
  const [products, setProducts] = useState([]);
  const {merchantInfo} = useSelector((state) => state.merchant);
  const [fetchReservationProducts, {isLoading: isLoading}] = useFetchReservationProductsMutation();
  const [placeReservation, {isLoading: isPlaceReservationLoading}] = usePlaceReservationMutation();
  const navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      const products = await fetchReservationProducts();
      setProducts(products.data.data.results);
    };
    fetchData()
      .catch(console.error);

  }, []);
  return (<>
    <CustomNavbar/>
    {isLoading && <CustomSpinner/>}

    {!isLoading && (<Container className={'product-details'}>
      <Row className="justify-content-md-center">

        <Card className={'text-center mb-3'}>
          <Card.Body>
            <Card.Title><i className="bi bi-journal-check pe-2"></i> {'Make a reservation'}</Card.Title>
          </Card.Body>
        </Card>
        <Container>
          <Formik
            initialValues={{product: '', property: null}}
            validationSchema={PlaceReservationSchema}
            validate={values => {
              const errors = {};
              if (!values.product || values.product === '') {
                errors.product = 'Required';
              }
              return errors;
            }}
            onSubmit={async (values, {setSubmitting}) => {
              console.log('onSubmit');
              const result = await placeReservation({
                body: {
                  order_items: [{origin: values.product}],
                  property: values.property
                }
              });
              if (result && result.data && result.data.status === 'success') {
                // navigate('/reservation/success');

              }
              setSubmitting(false);
            }}
          >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
                dirty,
                setFieldValue,
                isSubmitting
                /* and other goodies */
              }) => (
              <Form>
                <Card className={'text-center reservation'}>
                  <Card.Body className={'text-start'}>
                    <div className={'mb-2 text-left p-3'}>
                      <Card.Subtitle className="mb-2 text-muted">Місце:</Card.Subtitle>
                      <div className={'text-start'}>
                        {merchantInfo.properties && merchantInfo.properties && merchantInfo.properties.map(property => (
                          <div
                            className={`product p-2 m-2 w-100 ${property.id.toString() == values.property?.toString() ? 'active' : ''}`}
                            key={property.id} onClick={() => setFieldValue('property', property.id.toString())}>
                            <div>
                              <i
                                className={`bi p-2 ${property.id.toString() == values.property?.toString() ? 'bi-check-square' : 'bi-square'}`}></i>
                              {property.name}</div>
                          </div>))}
                      </div>
                    </div>
                    <div className={'mb-5 text-left p-3'}>
                      <Card.Subtitle className="mb-2 text-muted">Послуга:</Card.Subtitle>
                      <div className={'text-start'}>
                        {products && products.map(product => (
                          <div
                            className={`product p-2 m-2 w-100 ${product.id.toString() == values.product.toString() ? 'active' : ''}`}
                            key={product.id} onClick={() => setFieldValue('product', product.id.toString())}>
                            <div>
                              <i
                                className={`bi p-2 ${product.id.toString() == values.product.toString() ? 'bi-check-square' : 'bi-square'}`}></i>
                              {product.name}</div>
                          </div>))}
                      </div>
                    </div>
                    {/*<div className={'mb-5'}>*/}
                    {/*  <Card.Subtitle className="mb-2 text-muted">Час:</Card.Subtitle>*/}
                    {/*  <Card.Text>Soon...</Card.Text>*/}
                    {/*</div>*/}
                    <div className={'text-center'}>
                      <Button disabled={!dirty || !isValid} type={'submit'} variant={"dark"}>Reserve</Button>
                    </div>
                  </Card.Body>
                </Card>
              </Form>)}
          </Formik>
        </Container>
        <div className={'footer text-center'}>
          <PoweredBy/>
        </div>

      </Row>
    </Container>)}

  </>);
}

export default Reservation;