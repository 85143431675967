import {Route, Routes, BrowserRouter} from "react-router-dom";
import Categories from "./views/Categories";
import Feedback from "./views/Feedback";
import Home from "./views/Home";
import Layout from "./components/Layout";
import {Provider} from "react-redux";
import {store} from "./store/store";
import {HelmetProvider} from "react-helmet-async";
import ReactGA from "react-ga4";
import ProductDetails from "./views/ProductDetails";
import Reservation from "./views/Reservation";
import ReservationSuccess from "./views/ReservationSuccess";

function App() {

    if (process.env.REACT_APP_ENVIRONMENT !== 'localhost') {
        ReactGA.initialize("G-CK35XXWH7J");
        ReactGA.send("pageview");
    }


    return (
        <HelmetProvider>
            <div className="App">
                <Provider store={store}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Layout/>}>
                                <Route index element={<Home/>}/>
                                <Route path="menus/:id" element={<Categories/>}/>
                                <Route path="feedback" element={<Feedback/>}/>
                                <Route path="categories/item" element={<ProductDetails/>}/>
                                <Route path="products/:id" element={<ProductDetails/>}/>
                                <Route path="reservation/success" element={<ReservationSuccess/>}/>
                                <Route path="reservation" element={<Reservation/>}/>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </Provider>

            </div>
        </HelmetProvider>

    );
}

export default App;
