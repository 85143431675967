export const CATEGORIES_DUMMY = [
  {
    "name": "Drinks",
    "items": [
      {
        "name": "Coffee",
        "price": 22.33
      },
      {
        "name": "Tea",
        "price": 2.33
      }
    ]
  },
  {
    "name": "Breakfast",
    "items": [
      {
        "name": "English Breakfast",
        "price": 22.33
      },
      {
         "name": "France Breakfast",
        "price": 2.33
      }
    ]
  },
  {
    "name": "Dinner",
    "items": [
      {
        "name": "Fish",
        "price": 22.33
      },
      {
         "name": "Meat",
        "price": 2.33
      }
    ]
  }
]