import {useEffect, useState} from "react";
import "./categories.css";
import { Card, Col, Container, Row} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import {useFetchCategoryListMutation} from "../store/services/api";
import {useSelector} from "react-redux";


function Categories() {
    const {merchantInfo} = useSelector((state) => state.merchant);
    const [categories, setCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState(null);
    const [products, setProducts] = useState(null);

    const {id} = useParams();
    const [fetchCategoryList, {isLoading}] = useFetchCategoryListMutation();

    function selectCategory(id) {
        setActiveCategory(id);
        setProducts(categories.find(cat => cat.id === id).products);
    }

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchCategoryList({id});
            console.log(data);
            if (data) {
                setCategories(data.data.results);
                if (data.data.results.length > 0) {
                    setActiveCategory(data.data.results[0].id);
                    setProducts(data.data.results[0].products);
                }
            }
        };
        fetchData()
            .catch(console.error);
    }, []);
    return (<>
        <div className={'header'}>
            <Row>
                <Col className={'text-left'}>
                    <Link className={'p-3 d-inline-block'} to={'/'}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_0_1176)">
                                <path
                                    d="M10.828 12L15.778 16.95L14.364 18.364L8 12L14.364 5.63599L15.778 7.04999L10.828 12Z"
                                    fill="black"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_0_1176">
                                    <rect width="24" height="24" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>

                    </Link>
                </Col>
                <Col>
                    <h2 className={'restaurant-name p-3'}>{merchantInfo.name}</h2>
                </Col>
                <Col>
                </Col>

            </Row>


            <div className={'categories'}>
                {!isLoading && categories && categories.length > 0 && categories.map(category => {
                    return (
                        <span key={category.id}
                              className={`category mt-2 mb-2 ${activeCategory === category.id ? 'active' : ''}`}
                              title={category.name}
                              onClick={() => selectCategory(category.id)}>
                            {category.name}
                        </span>
                    );
                })}
            </div>
        </div>
        <Container className={'product-list'}>
            {products && products.length > 0 && products.map(product => {
                return (<Row className="justify-content-md-center p-3 product-row" key={product.id}>
                    <Card className={'product-card'}>
                        {/*<Card.Img variant="top"*/}
                        {/*          style={{borderTopRightRadius: "20px", borderTopLeftRadius: "20px"}}*/}
                        {/*          src={picMenu1}/>*/}
                        <Card.Body>
                            <Card.Title>{product.name}</Card.Title>
                            {product.description && (
                                <Card.Text>
                                    <p className="second-name">{product.description}</p>
                                </Card.Text>
                            )}
                        </Card.Body>
                        <div className={'price-col'}>
                            <span className={'price rounded'}>{product.price} uah</span>
                        </div>
                    </Card>
                </Row>);
            })}

        </Container>


    </>);

}

export default Categories;